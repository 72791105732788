import React, { useEffect, useState } from "react";
import { Carousel, Form, Spinner } from "react-bootstrap";
import DropDownComponent from "../DropDownComponent";
import BaseDropDownItemsComponent from "../../../../components/BaseDropDownItemsComponent";
import { getYears, years } from "../../service/fetchDataCar";
import BaseSearchInputComponent from "../../../../components/BaseSearchInputComponent";
import BaseRadioButtonGroup from "../../../../components/BaseRadioButtonGroup";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { ModalStopperComponent } from "../ModalStopperComponent";
import { useSelector } from "react-redux";

export const CarouselComponent = ({
  selectedButton,
  isMobile,
  handleOptionSelect,
  handleItemClick,
  handleButtonClick,
  selectedOptions,
  setSelectedOptions,
  selectedItem,
  isGnc,
  setIsGnc,
  number,
  dataBrands,
  dataBrandsSearch,
  dataModels,
  dataModelsSearch,
  setDataBrands,
  setDataModels,
  dataVersionsSearch,
  dataVersions,
  setDataVersions,
  handleInputChange,
  setShowModalForm,
  setNumber,
  setShowDropdownKms,
  currentView,
  setCurrentView,
  handleStartClick,
  sendQuote,
  setSendQuote,
  loadingSend,
  handleShowModalForm,
}) => {
  const [showModalStopper, setShowModalStopper] = useState(false);
  const [dataYears, setDataYears] = useState();

  const isFormValid = isGnc !== "" && number !== "";

  useEffect(() => {
    getYears(setDataYears);
  }, []);

  const handleOptionChange = (e) => {
    setSendQuote(e.target.value);
  };

  return (
    <>
      <Carousel
        className="carousel-sale"
        controls={false}
        indicators={false}
        interval={null}
        activeIndex={selectedButton - 1}
      >
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿De qué año es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("year", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(2);
            }}
            isDisabled={false} // Siempre habilitado en el primer paso
            selectedOptions={selectedOptions?.year?.name}
          >
            <BaseDropDownItemsComponent
              items={dataYears}
              onItemClick={handleItemClick}
              selectedItem={selectedItem}
              isYear={true}
              setSelectedOptions={setSelectedOptions}
              dataSelectedOptions={selectedOptions}
              type={"year"}
              setKms={setNumber}
              setGnc={setIsGnc}
            />
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué marca es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("brand", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(3);
            }}
            isDisabled={!selectedOptions?.year}
            selectedOptions={selectedOptions?.brand?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá la marca"}
              dataSearch={dataBrandsSearch}
              setDataSearch={setDataBrands}
            />

            {!dataBrands ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "85%" }}
              >
                <Spinner
                  animation="border"
                  style={{
                    color: " #7340d8",
                  }}
                />
              </div>
            ) : (
              <BaseDropDownItemsComponent
                items={dataBrands}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"brand"}
                setKms={setNumber}
                setGnc={setIsGnc}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué modelo es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("model", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(4);
            }}
            isDisabled={!selectedOptions?.brand}
            selectedOptions={selectedOptions?.model?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá el modelo"}
              dataSearch={dataModelsSearch}
              setDataSearch={setDataModels}
            />
            {!dataModels ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "85%" }}
              >
                <Spinner
                  animation="border"
                  style={{
                    color: " #7340d8",
                  }}
                />
              </div>
            ) : (
              <BaseDropDownItemsComponent
                items={dataModels}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"model"}
                setKms={setNumber}
                setGnc={setIsGnc}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué versión es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("version", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(5);
            }}
            isDisabled={!selectedOptions?.model}
            selectedOptions={selectedOptions?.version?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá la version"}
              dataSearch={dataVersionsSearch}
              setDataSearch={setDataVersions}
            />
            {!dataVersions ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "85%" }}
              >
                <Spinner
                  animation="border"
                  style={{
                    color: " #7340d8",
                  }}
                />
              </div>
            ) : (
              <BaseDropDownItemsComponent
                items={dataVersions}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"version"}
                setKms={setNumber}
                setGnc={setIsGnc}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            textButton={"¿Cuántos kms tiene tu auto?"}
            onItemClick={(selectedItem) => {
              handleOptionSelect("kms", selectedItem);
              handleItemClick(selectedItem);
              handleButtonClick(6);
            }}
            isDisabled={!selectedOptions?.version}
          >
            <input
              type="text"
              className="base-input mt-3 mb-3 text-center"
              value={number}
              onChange={handleInputChange}
              placeholder="Ej: 24.000"
            />
            <div
              style={{
                display: "flex",
                height: "290px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: " space-between",
              }}
            >
              <BaseRadioButtonGroup
                isGnc={isGnc}
                setIsGnc={setIsGnc}
                kms={number}
                version_id={selectedOptions?.version?.id}
                setShowModalStopper={setShowModalStopper}
              />
              <div>
                <BasePurpleButtonComponent
                  buttonText={"Continuar"}
                  onClick={() => {
                    handleButtonClick(6);
                    setShowModalForm(true);
                  }}
                  buttonDisabled={!isFormValid}
                />
              </div>
            </div>
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            textButton={"¿Enviar cotización?"}
            onItemClick={(selectedItem) => {
              handleOptionSelect("kms", selectedItem);
              handleItemClick(selectedItem);
              handleButtonClick(6);
            }}
            isDisabled={!selectedOptions?.version}
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: " space-between",
              }}
            >
              <div className="radio-button-container mt-3">
                <Form.Check
                  className="radio-button-input"
                  type="radio"
                  label="Sí"
                  value="Si"
                  checked={sendQuote === "Si"}
                  onChange={handleOptionChange}
                />
                <div className="ms-5">
                  <Form.Check
                    className="radio-button-input"
                    type="radio"
                    label="No"
                    value="No"
                    checked={sendQuote === "No"}
                    onChange={handleOptionChange}
                  />
                </div>
              </div>
              <div className="mb-2">
                {loadingSend ? (
                  <Spinner
                    animation="border"
                    style={{
                      color: " #7340d8",
                      marginTop: "110px",
                    }}
                  />
                ) : (
                  <BasePurpleButtonComponent
                    buttonText={"Continuar"}
                    onClick={handleShowModalForm}
                    buttonDisabled={!isFormValid}
                  />
                )}
              </div>
            </div>
          </DropDownComponent>
        </Carousel.Item>
      </Carousel>
      <ModalStopperComponent
        showModalStopper={showModalStopper}
        setShowModalStopper={setShowModalStopper}
        setSelectedOptions={setSelectedOptions}
        setNumber={setNumber}
        setIsGnc={setIsGnc}
        isMobile={true}
        setShowDropdownKms={setShowDropdownKms}
        handleButtonClick={handleButtonClick}
      />
    </>
  );
};
